export const FORMATS = {
  leaderboard: {
    desktop: [[728, 90], [970, 90]],
    tablet: [[728, 90], [970, 90]],
    mobile: [[320, 50]]
  },
  leaderboard2: {
    desktop: [[468, 60]],
    tablet: [[468, 60]],
    mobile: [[320, 50]]
  },
  leaderboard3: {
    desktop: [[728, 90]],
    tablet: [[728, 90]],
    mobile: [[320, 50]]
  },
  mpu: {
    desktop: [[300, 250]],
    tablet: [[300, 250]],
    mobile: [[300, 250]]
  },
  mpu2: {
    desktop: [[300, 250]],
    tablet: [[300, 250]],
    mobile: [[300, 600]]
  },
  wingbanner: {
    desktop: [[160, 600]],
    tablet: [],
    mobile: []
  },
  interstitial: {
    desktop: [[2, 2]],
    tablet: [[2, 2]]
  },
  halfpage: {
    desktop: [[300, 600]],
    tablet: [[300, 600]],
    mobile: [[300, 600]]
  }
}

export default FORMATS
